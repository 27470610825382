
import styled from "styled-components";

export const AddButton = styled('div')`
 font-family: var(--bold);
  font-size: 14px;
  text-decoration: underline;
  color: #DF7411;
`

export const ItemWrap = styled('div')`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  margin-top: 20px;
  border: 1px solid #969FA2;
  border-radius: 6px;
  justify-content: flex-start;
  padding: 12px 16px;
`
export const ItemText = styled('div')`
  font-family: var(--regular);
  font-size: 14px;
  color: var(--text);
  letter-spacing: 0.3px;

`
export const FileName = styled('a')`
  font-family: var(--regular);
  font-size: 14px;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
  color: #DF7411;
  cursor: pointer;
`

export const AddedBy = styled('div')`
  font-family: var(--regular);
  font-size: 12px;
  color: var(--text);
  letter-spacing: 0.3px;
`