import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../../../../../mobx-store/context'
import { useForm } from 'react-hook-form'
import Input from '../../../../../../components/common/Input'
import Button from '../../../../../../components/common/Button/button'
import { Title } from '../../../../components/common/style'
import ResourceModal from '../../../../components/ResourceAccessModal/resourceModal'
import { getError } from '../../../../../../helpers/helpers'
import { api } from '../../../../../../api'
import Table from '../../Table'
import { checkRole } from '../../../../../../helpers/helpers'
import { Back } from '../Tracking/style'
import AutoAssignmentsInfoModal from '../LoadingModal'


const assignmentTypesPending = [
  {
    id: 'license_source_jurisdiction',
    name: 'Jurisdictions',
  },
  {
    id: 'license_type',
    name: 'Type',
  },
  {
    id: 'jurisdiction_and_license_type',
    name: 'Jurisdictions and Types',
  },
]

const assignmentTypesRenewals = [
  {
    id: 'license_source_jurisdiction',
    name: 'Jurisdictions',
  },
  {
    id: 'license_type',
    name: 'Type',
  },
  {
    id: 'jurisdiction_and_license_type',
    name: 'Jurisdictions and Types',
  },
]



const LicenseTab = observer(({ setShowActivity }) => {
  const store = useStore()
  const history = useHistory()
  const [assignTypePending, setAssignTypePending] = useState('')
  const [assignTypeRenewals, setAssignTypeRenewals] = useState('')
  const [memberIdPending, setMemberIdPending] = useState('')
  const [memberIdRenewals, setMemberIdRenewals] = useState('')
  const [btnLoadingPending, setBtnLoadingPending] = useState(false)
  const [btnLoadingRenewals, setBtnLoadingRenewals] = useState(false)
  const [visible, setVisible] = useState(false)
  const [dataPending, setDataPending] = useState([])
  const [dataRnewals, setDataRenewals] = useState([])

  const { handleSubmit, errors, control, reset, setError } = useForm()

  const members = store?.members
    .filter((item) => {
      return (
        item.is_active &&
        item.have_access_to_license_source &&
        checkRole([item.license_source_role], ['admin', 'license_owner',])
      )
    })
    .map((item) => (
      {
        id: item.id,
        name: item.first_name + ' ' + item.last_name
      }
    ))

  if (!store.user?.[0]?.have_access_to_license_source
    || !store.company?.[0]?.have_access_to_license_source)
    return (
      <ResourceModal
        type='licence'
        to='/edit-profile'
        onClose={() => history.push('/edit-profile')}
      />
    )

  const onSubmit = (values, type) => {
    const isPending = type === 'pending'
    const dataPending = {
      license_pending_auto_assign_jurisdictions: values.license_pending_auto_assign_jurisdictions,
      license_pending_auto_assign_license_type: values.license_pending_auto_assign_license_type,
      auto_assign: true
    }
    const dataRenewals = {
      license_renewals_auto_assign_jurisdictions: values.license_renewals_auto_assign_jurisdictions,
      license_renewals_auto_assign_license_types: values.license_renewals_auto_assign_license_types,
      auto_assign: true
    }
    isPending ? setBtnLoadingPending(true) : setBtnLoadingRenewals(true)
    setVisible(true)
    const memberId = isPending ? memberIdPending : memberIdRenewals
    api(`/members/${memberId}/`, { ...isPending ? dataPending : dataRenewals }, 'PATCH',).then(
      (data) => {
        isPending ? setBtnLoadingPending(false) : setBtnLoadingRenewals(false)
        if (!data.errors) {
          type === 'pending' ? reset({
            license_pending_auto_assign_jurisdictions: '',
            license_pending_auto_assign_license_type: '',
          })
            : reset({
              license_renewals_auto_assign_jurisdictions: '',
              license_renewals_auto_assign_license_types: '',
            })
          isPending ? setMemberIdPending('') : setMemberIdRenewals('')
          getAutoAssignMembers(type)
          api('/companies/auto_assign_lists/', {}, 'GET').then((data) => {
            if (!data.errors) {
              store.setSingleValue('autoAssignLists', data)
            }
          })
        } else {
          getError(data, setError)
        }
      },
    )
  }

  const onAssignmentTypeChange = (value, type) => {
    api(`/companies/auto_assign_type/`, { auto_assign_by: value, license_source: type }, 'PATCH').then(
      (data) => {
        if (!data.errors) {
          store.setSingleValue('autoAssignType', data)
        } else {
          getError(data, setError)
        }
      },
    )
  }

  const handleSubmitWithType = (type) => (values) => {
    onSubmit(values, type);
  };

  const getAutoAssignMembers = (type) => {
    const isPending = type === 'pending'
    api(`/companies/auto_assign_members/?filter_for=${isPending ? 'license_source_pending' : 'license_source_renewals'}`, {}, 'GET').then((data) => {
      isPending ? store.setSingleValue('autoAssignMembersPending', data) : store.setSingleValue('autoAssignMembersRenewals', data)
      isPending ? setDataPending(data) : setDataRenewals(data)
    })

  }


  useEffect(() => {
    setAssignTypePending(store.autoAssignType.license_source_pending_auto_assign_type)
    setAssignTypeRenewals(store.autoAssignType.license_source_renewals_auto_assign_type)
  }, [])

  useEffect(() => {
    getAutoAssignMembers('pending')
    getAutoAssignMembers('renewals')
  }, [])

  return (
    <>
      {visible && <AutoAssignmentsInfoModal onClose={() => setVisible(false)} />}
      <Title style={{ fontSize: 14, marginBottom: 15, fontFamily: 'var(--regular)' }}>
        Select the type of data, then user, to set up an auto assignment. <br />
        The user will automatically be assigned as the License Owner of the selected items.
        <Back onClick={() => setShowActivity(true)}>View Auto Assignment Activity</Back>
      </Title>
      <Title style={{ marginBottom: 10 }}>Pending License Assessments</Title>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ width: 270 }}>
          <Input
            defaultValue={store?.autoAssignType?.license_source_pending_auto_assign_type}
            control={control}
            name='auto_assign_by_pending'
            type='select'
            placeholder='Select Auto Assignment Type'
            options={assignmentTypesPending}
            styleContainer={{ marginRight: 15, width: 250 }}
            handleChange={(value) => {
              setAssignTypePending(value)
              onAssignmentTypeChange(value, 'pending')
            }}
            smallLabel
            getPopupContainer={true}
            disabled={store?.autoAssignMembersPending.length > 0}
          />
        </div>
        <div style={{ fontSize: 16, fontFamily: 'var(--regular)', color: '#515151' }}>
          Selecting an Assignment Type applies to all users.
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: 20 }}>
        <Input
          control={control}
          name='member_pending'
          type='select'
          placeholder='Select a User'
          options={members}
          style={{ height: 30 }}
          styleContainer={{ marginRight: 15 }}
          handleChange={(value) => setMemberIdPending(value)}
          smallLabel
          getPopupContainer={true}
          disabled={!assignTypePending}
        />
        <Input
          selectAll={true}
          control={control}
          name='license_pending_auto_assign_jurisdictions'
          type='multiSelect'
          placeholder='Select Jurisdiction'
          options={store?.autoAssignLists?.license_source_pending_jurisdictions.map((item) => {
            return {
              ...item,
              disabled: item.is_taken,
              name: item.name + (item.is_taken ? ' (taken)' : '')
            }
          })}
          styleContainer={{ marginRight: 15 }}
          style={{ width: 180 }}
          smallLabel
          showSearch
          getPopupContainer={true}
          disabled={assignTypePending === 'license_type' || !assignTypePending || !memberIdPending}
        />
        <Input
          control={control}
          selectAll={true}
          name='license_pending_auto_assign_license_type'
          type='multiSelect'
          placeholder='Select Type'
          options={store?.autoAssignLists?.license_source_pending_license_types.map((item) => {
            return {
              ...item,
              disabled: item.is_taken,
              name: item.name + (item.is_taken ? ' (taken)' : '')
            }
          })}
          styleContainer={{ marginRight: 15 }}
          style={{ minWidth: 180 }}
          smallLabel
          showSearch
          getPopupContainer={true}
          disabled={assignTypePending === 'license_source_jurisdiction' || !assignTypePending || !memberIdPending}
        />
        <Button
          text={'Add Auto Assignment'}
          onClick={handleSubmit(handleSubmitWithType('pending'))}
          type={'main'}
          loading={btnLoadingPending}
          disabled={!assignTypePending || !memberIdPending}
        />
      </div>
      <Table data={dataPending} setData={(value) => setDataPending(value)} reset={(value) => { reset(value) }} serviceName='license_source_pending' />

      <Title style={{ marginBottom: 10 }}>License Renewals </Title>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ width: 270 }}>
          <Input
            defaultValue={store?.autoAssignType?.license_source_renewals_auto_assign_type}
            control={control}
            name='auto_assign_by_renewals'
            type='select'
            placeholder='Select Auto Assignment Type'
            options={assignmentTypesRenewals}
            styleContainer={{ marginRight: 15, width: 250 }}
            handleChange={(value) => {
              setAssignTypeRenewals(value)
              onAssignmentTypeChange(value, 'renewals')
            }}
            smallLabel
            getPopupContainer={true}
            disabled={store?.autoAssignMembersRenewals.length > 0}
          />
        </div>
        <div style={{ fontSize: 16, fontFamily: 'var(--regular)', color: '#515151' }}>
          Selecting an Assignment Type applies to all users.
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: 20 }}>
        <Input
          control={control}
          name='member_renewals'
          type='select'
          placeholder='Select a User'
          options={members}
          style={{ height: 30 }}
          styleContainer={{ marginRight: 15 }}
          handleChange={(value) => setMemberIdRenewals(value)}
          smallLabel
          getPopupContainer={true}
          disabled={!assignTypeRenewals}
        />
        <Input
          selectAll={true}
          control={control}
          name='license_renewals_auto_assign_jurisdictions'
          type='multiSelect'
          placeholder='Select Jurisdiction'
          options={store?.autoAssignLists?.license_source_renewals_jurisdictions.map((item) => {
            return {
              ...item,
              disabled: item.is_taken,
              name: item.name + (item.is_taken ? ' (taken)' : '')
            }
          })}
          styleContainer={{ marginRight: 15 }}
          style={{ width: 180 }}
          smallLabel
          showSearch
          getPopupContainer={true}
          disabled={assignTypeRenewals === 'license_type' || !assignTypeRenewals || !memberIdRenewals}
        />
        <Input
          control={control}
          selectAll={true}
          name='license_renewals_auto_assign_license_types'
          type='multiSelect'
          placeholder='Select Type'
          options={store?.autoAssignLists?.license_source_renewals_license_types.map((item) => {
            return {
              ...item,
              disabled: item.is_taken,
              name: item.name + (item.is_taken ? ' (taken)' : '')
            }
          })}
          styleContainer={{ marginRight: 15 }}
          style={{ minWidth: 180 }}
          smallLabel
          showSearch
          getPopupContainer={true}
          disabled={assignTypeRenewals === 'license_source_jurisdiction' || !assignTypeRenewals || !memberIdRenewals}
        />
        <Button
          text={'Add Auto Assignment'}
          onClick={handleSubmit(handleSubmitWithType('renewals'))}
          type={'main'}
          loading={btnLoadingRenewals}
          disabled={!assignTypeRenewals || !memberIdRenewals}
        />
      </div>
      <Table data={dataRnewals} setData={(value) => setDataRenewals(value)} reset={(value) => { reset(value) }} serviceName='license_source_renewals' />

      {/* <Title style={{ marginBottom: 10 }}>License Reporting </Title>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ width: 270 }}>
          <Input
            defaultValue={store?.autoAssignType?.report_source_auto_assign_type}
            control={control}
            name='auto_assign_by'
            type='select'
            placeholder='Select Auto Assignment Type'
            options={assignmentTypes}
            styleContainer={{ marginRight: 15, width: 250 }}
            handleChange={(value) => {
              setAssignType(value)
              onAssignmentTypeChange(value)
            }}
            smallLabel
            getPopupContainer={true}
            disabled={store?.autoAssignMembers.length > 0}
          />
        </div>
        <div style={{ fontSize: 16, fontFamily: 'var(--regular)', color: '#515151' }}>
          Selecting an Assignment Type applies to all users.
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: 20 }}>
        <Input
          control={control}
          name='member'
          type='select'
          placeholder='Select a User'
          options={members}
          style={{ height: 30 }}
          styleContainer={{ marginRight: 15 }}
          handleChange={(value) => setMemberId(value)}
          smallLabel
          getPopupContainer={true}
          disabled={!assignType}
        />
        <Input
          selectAll={true}
          control={control}
          name='report_source_jurisdictions_auto_assign'
          type='multiSelect'
          placeholder='Select Jurisdiction'
          options={store?.autoAssignLists?.report_source_jurisdictions.map((item) => {
            return {
              ...item,
              disabled: item.is_taken,
              name: item.name + (item.is_taken ? ' (taken)' : '')
            }
          })}
          styleContainer={{ marginRight: 15 }}
          style={{ width: 180 }}
          smallLabel
          showSearch
          getPopupContainer={true}
          disabled={assignType === 'report_type' || !assignType || !memberId}
        />
        <Input
          control={control}
          selectAll={true}
          name='report_source_report_types_auto_assign'
          type='multiSelect'
          placeholder='Select Type'
          options={store?.autoAssignLists?.report_source_report_types.map((item) => {
            return {
              ...item,
              disabled: item.is_taken,
              name: item.name + (item.is_taken ? ' (taken)' : '')
            }
          })}
          styleContainer={{ marginRight: 15 }}
          style={{ minWidth: 180 }}
          smallLabel
          showSearch
          getPopupContainer={true}
          disabled={assignType === 'report_source_jurisdiction' || !assignType || !memberId}
        />
        <Button
          text={'Add Auto Assignment'}
          onClick={handleSubmit(onSubmit)}
          type={'main'}
          loading={btnLoading}
          disabled={!assignType || !memberId}
        />
      </div>
      <Table data={data} setData={(value) => setData(value)} reset={(value) => { reset(value) }} serviceName='report_source' /> */}
    </>
  )
})

export default LicenseTab
