import { useStore } from '../../../mobx-store/context';
import { Calendar, Select, Radio, Col, Row, } from 'antd';
import { useState, useEffect } from 'react';
import FullScreenLoader from '../../../components/common/FullScreenLoader';
import moment from 'moment'
import { Wrap } from './style'
import DataList from './DataList';
import Search from '../Search/search';
import './styles.css'
import { observer } from 'mobx-react-lite';



const ReportCalendar = observer(({ setActive,setLsChapter, getCurrentLegislative, getData, isLicenseSource, isReportSource }) => {
  const store = useStore();
  const [data, setData] = useState([])
  const [currentMonth, setCurrentMonth] = useState({
    report_due_date_after: moment().startOf('month').format('YYYY-MM-DD'),
    report_due_date_before: moment().endOf('month').format('YYYY-MM-DD')
  });

  useEffect(() => {
    !isLoading && setData(store.legislatives.results)
  }, [store.legislatives])


  useEffect(() => {
    store.setSingleValue('calendarDateRange', currentMonth)
    getData()
  }, [currentMonth]);

  const dateCellRender = (value) => {
    const stringValue = value.format('yyyy-MM-DD');
    const listData = isLicenseSource
      ? data.filter((item) => item.type === 'report' ? item.report_due_date === stringValue : item.renewal_date === stringValue)
      : data.filter((report) => report.report_due === stringValue)

    return (
      <DataList
        listData={listData}
        setActive={setActive}
        setLsChapter={setLsChapter}
        getCurrentLegislative={getCurrentLegislative}
        isLicenseSource={isLicenseSource}
      />
    );
  };


  const monthCellRender = (value) => {
    const stringValue = value.format('YYYY-MM');
    const listData = isLicenseSource
      ? data.filter((item) => item.type === 'report' ? item.report_due_date.slice(0,-3) === stringValue : item.renewal_date.slice(0,-3) === stringValue)
      : data.filter((report) => report.report_due.slice(0, -3) === stringValue)

    return (
      <DataList
        listData={listData}
        setLsChapter={setLsChapter}
        setActive={setActive}
        getCurrentLegislative={getCurrentLegislative}
        isLicenseSource={isLicenseSource}
      />
    );
  };

  const isLoading = !store.legislatives


  return (
    <>
      {isLoading && <FullScreenLoader />}
      {!isLoading &&
        <Wrap>
          <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender}
            locale={{
              lang: {
                locale: 'en',
                dayFormat: moment.updateLocale('en', {
                  weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                }),
                monthsFormat: moment.updateLocale('en', {
                  months: ['January', 'February', 'March', 'April ', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
                }),
              }
            }}
            className={'reportCalendar'}
            headerRender={({ value, type, onChange, onTypeChange }) => {
              const start = 0;
              const end = 12;
              const monthOptions = [];

              const current = value.clone();
              const localeData = value.localeData();
              const months = [];
              for (let i = 0; i < 12; i++) {
                current.month(i);
                months.push(localeData.monthsShort(current));
              }

              for (let index = start; index < end; index++) {
                monthOptions.push(
                  <Select.Option className="month-item" key={`${index}`}>
                    {months[index]}
                  </Select.Option>
                );
              }
              const month = value.month();
              const year = value.year();
              const greatestDueDate = store?.company[0]?.greatest_report_due_date === null
                ? year + 1
                : +moment(store?.company[0]?.greatest_report_due_date).format('YYYYY')
              const options = [];
              for (let i = +moment(store?.company[0]?.report_source_registration_date).format('YYYY'); i <= greatestDueDate; i += 1) {
                options.push(
                  <Select.Option key={i} value={i} className="year-item">
                    {i}
                  </Select.Option>
                );
              }
              return (
                <>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ padding: '45px 0' }}>
                      <Row gutter={8}>
                        <Col>
                          <Radio.Group
                            size="large"
                            onChange={(e) => {
                              onTypeChange(e.target.value);
                              if (e.target.value === "year") {
                                const now = value.clone();
                                setCurrentMonth({
                                  report_due_date_after: now.startOf('year').format('YYYY-MM-DD'),
                                  report_due_date_before: now.endOf('year').format('YYYY-MM-DD')
                                });
                              }
                              else {
                                const now = value.clone();
                                setCurrentMonth({
                                  report_due_date_after: now.startOf('month').format('YYYY-MM-DD'),
                                  report_due_date_before: now.endOf('month').format('YYYY-MM-DD')
                                });
                              }
                            }}
                            value={type}
                          >
                            <Radio.Button value="month">Monthly</Radio.Button>
                            <Radio.Button value="year">Yearly</Radio.Button>
                          </Radio.Group>
                        </Col>
                        <Col>
                          <Select
                            size="large"
                            dropdownMatchSelectWidth={false}
                            className="my-year-select"
                            onChange={(newYear) => {
                              const now = value.clone().year(newYear);
                              setCurrentMonth(null)
                              setCurrentMonth({
                                report_due_date_after: now.startOf('year').format('YYYY-MM-DD'),
                                report_due_date_before: now.endOf('year').format('YYYY-MM-DD')
                              });
                              onChange(now);
                            }}

                            value={String(year)}
                          >
                            {options}
                          </Select>
                        </Col>
                        <Col>
                          <Select
                            size="large"
                            dropdownMatchSelectWidth={false}
                            value={String(month)}
                            onChange={(selectedMonth) => {
                              const newValue = value.clone();
                              newValue.month(parseInt(selectedMonth, 10));

                              const startOfMonth = newValue.clone().startOf('month');
                              const endOfMonth = newValue.clone().endOf('month');

                              setCurrentMonth({
                                report_due_date_after: startOfMonth.startOf('week').format('YYYY-MM-DD'),
                                report_due_date_before: endOfMonth.endOf('week').format('YYYY-MM-DD')
                              });
                              onChange(newValue);
                            }}
                          >
                            {monthOptions}
                          </Select>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div style={{ display: 'flex', width: '100%', marginTop: '-95px' }}>
                    <Search
                      auth={false}
                      closed={false}
                      all={false}
                      monitor={false}
                      apcd={false}
                      isTrial={false}
                      calendar={true}
                      onSearch={() => { }}
                      isMyTasks={false}
                      isLicenseSource={isLicenseSource}
                      activeTab={'calendar'}
                      isReportSource={isReportSource}
                      getData={() => getData()}
                      showSearchByWord={false}
                    />
                  </div>
                </>
              );
            }
            }
          />
        </Wrap>
      }
    </>
  )

})

export default ReportCalendar