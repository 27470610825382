import React, { useEffect } from 'react'
import { Title } from './style'
import CheckboxComponent from './CheckboxList'
import { EmptyBlock } from '../../right.style'

const GetReady = ({ title, reportCompanyId, handleOpen, setAssigneNew, getReadyData, receiveGetReadyData, assignee, isLicenseSource, renewals = false, determinationId }) => {

  useEffect(() => {
    receiveGetReadyData(isLicenseSource, !isLicenseSource, renewals, determinationId)
  }, [])

  return (
    <>
      <Title>
        {title}
      </Title>
      {getReadyData && getReadyData.length > 0 ?
        <CheckboxComponent
          assignee={assignee}
          data={getReadyData}
          reportCompanyId={reportCompanyId}
          getData={receiveGetReadyData}
          handleOpen={handleOpen}
          isLicenseSource={isLicenseSource}
          renewals={renewals}
          setAssigneNew={(value) => setAssigneNew(value)} />
        : <EmptyBlock>Not Specified.</EmptyBlock>
      }
    </>
  )
}

export default GetReady