import React, { useMemo } from 'react'
import moment from 'moment'
import { useStore } from '../../../../mobx-store/context'
import { DatePicker } from 'antd'


const { RangePicker } = DatePicker;


const InputDateRange = ({
  name,
  visible,
  onChange,
  reset,
  handleChange,
  disabled = false,
  getPopupContainer = false,
  value, }) => {

  const store = useStore()
  const localValue = useMemo(() => {
    if (value) {
      return [moment(value?.split('±')[0]), moment(value?.split('±')[1])]
    }
    return []
  }, [value])

  const parentNode = () => {
    if (getPopupContainer) {
      return (trigger) => trigger.parentNode.parentNode
    }
    return false
  }

  return (
    <RangePicker
      disabled={disabled}
      format="MM/DD/YYYY"
      disabledDate={d => !d || d.isAfter("2030-12-31") || d.isSameOrBefore("2022-01-01")}
      value={localValue}
      open={visible}
      dropdownClassName={'dateRangePicker'}
      getPopupContainer={parentNode()}
      bordered={false}
      allowEmpty={[false, true]}
      onChange={(date, dateString) => {
        if (date === null) {
          const newSearchObj = store.searchableObject

          delete newSearchObj.adopted_date_after
          delete newSearchObj.adopted_date_before
          delete newSearchObj.primary_due_date_before
          delete newSearchObj.primary_due_date_after
          delete newSearchObj.expiration_date_before
          delete newSearchObj.expiration_date_after
          delete newSearchObj.internal_due_date_before
          delete newSearchObj.internal_due_date_after

          store.setSingleValue('searchableObject', newSearchObj)

          reset(name)

          handleChange && handleChange()
        } else {
          dateString[1]
            ? onChange(`${dateString[0]}±${dateString[1]}`)
            : onChange(`${dateString[0]}`)
          handleChange &&
            dateString[1]
            ? handleChange(`${dateString[0]}±${dateString[1]}`)
            : handleChange(`${dateString[0]}`)
        }
      }}
    />
  )
}

export default InputDateRange
