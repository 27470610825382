import React from 'react';
import { Wrap, Title } from './style';

function InterLinks({ data, store }) {


  return (
    <>
      {((!!data?.report_interlinking_items && !!data?.report_interlinking_items.length) ||
        (!!data?.compliance_interlinking_items && !!data?.compliance_interlinking_items.length)) &&
        <Wrap>
          {!!data?.report_interlinking_items && !!data?.report_interlinking_items.length &&
            <>
              <Title>View Related ReportSource Items: </Title>
              {data.report_interlinking_items.map((link, index) => (
                <a key={index} href={(!store.company[0].have_access_to_report_source || !store.user?.[0]?.have_access_to_report_source)
                  ? '/no-report-source-subscription/'
                  : link.url
                } target="_blank" rel="noopener noreferrer">
                  {link.name}&nbsp;&nbsp;|&nbsp;&nbsp;{link.jurisdiction}&nbsp;&nbsp;|&nbsp;&nbsp;Type: {link.report_type}
                </a>
              ))}
            </>
          }
          {
            !!data?.compliance_interlinking_items && !!data?.compliance_interlinking_items.length &&
            <>
              <Title>View Related ComplianceSource Items: </Title>
              {data.compliance_interlinking_items.length && data.compliance_interlinking_items.map((link, index) => (
                <a key={index} href={(!store.company[0].have_access_to_tracking || !store.user?.[0]?.have_access_to_tracking)
                  ? '/no-compliance-source-subscription/'
                  : link.url
                } target="_blank" rel="noopener noreferrer">
                  {link.name}&nbsp;&nbsp;|&nbsp;&nbsp;{link.jurisdiction}&nbsp;&nbsp;|&nbsp;&nbsp;Topic: {link.topic}
                </a>
              ))}
            </>
          }
        </Wrap >}
    </>
  );
}

export default InterLinks;
