import { Bottom, Inputs, ShowMore, TopLine, Wrap, CheckboxCover, CheckboxName } from './search.style'
import Input from '../../../components/common/Input'
import { Checkbox, Select } from 'antd'

const APCDInpits = ({ control,
  handleSubmit,
  onSubmit,
  listHeight,
  membersAdmins,
  store,
  activeTab,
  type,
  setValue,
  setIsDateRangeVisible,
  defaultReportRange,
  active,
  showSearchBlock,
  toggleLastSearch,
  remamberLastSearch,
  apcd,
  watch,
  register }) => {

  return (
    <>
      <Inputs style={{ marginBottom: '1em' }}>
        <Input
          type={'text'}
          name={'report_title'}
          control={control}
          placeholder={'Type a Report Name'}
          label={'Report Title'}
          handleChange={handleSubmit(onSubmit)}
          smallLabel
          transparent
          validation={{
            validate: () => true,
          }}
        />
        <Input
          type={'multiSelect'}
          listHeight={listHeight}
          selectAll={false}
          name={'report_type'}
          control={control}
          options={activeTab === 'main'
            ? store?.legislative_options?.report_types?.filter((item) => item.name !== 'APCD')
            : store?.legislative_options?.report_types}
          placeholder={'Select Report Type'}
          label={'Report Type'}
          handleChange={handleSubmit(onSubmit)}
          smallLabel
          disabled={apcd}
          transparent
          getPopupContainer={true}
        />
      </Inputs>
      <Inputs style={{ marginBottom: '1em' }}>
        <Input
          type={'multiSelect'}
          listHeight={listHeight}
          selectAll={false}
          name={'reporting_entity'}
          control={control}
          options={store?.legislative_options?.report_entities}
          placeholder={'Select Report Entity'}
          label={'Reporting Entity'}
          handleChange={handleSubmit(onSubmit)}
          smallLabel
          transparent
          getPopupContainer={true}
        />
        <Input
          type={'multiSelect'}
          listHeight={listHeight}
          selectAll={false}
          name={'jurisdiction'}
          control={control}
          placeholder={'Select Jurisdiction'}
          options={apcd
            ? store.legislative_options?.report_source_apcd_jurisdictions?.filter(
              (item) => (item.is_system ? item.enabled : true)
            )
            : store.legislative_options?.report_source_jurisdictions?.filter(
              (item) => (item.is_system ? item.enabled : true)
            )}
          label={'Jurisdiction'}
          handleChange={handleSubmit(onSubmit)}
          showDeprecatedLabel
          showDeprecated
          showSearch
          smallLabel
          smaillList
          transparent
          getPopupContainer={true}
        />
      </Inputs>
      <Inputs style={{ marginBottom: '1em' }}>
        <Input
          type={'text'}
          name={'report_to'}
          control={control}
          placeholder={'Type to search'}
          label={'Report To'}
          handleChange={handleSubmit(onSubmit)}
          smallLabel
          transparent
          getPopupContainer={true}
          validation={{
            validate: () => true,
          }}
        />
        <Input
          type={'date_range'}
          name={'report_due_date'}
          control={control}
          placeholder={'Select Report Due Date'}
          label={'Report Due Date'}
          handleChange={handleSubmit(onSubmit)}
          defaultValue={defaultReportRange}
          isSearchOpened={active || showSearchBlock}
          dateRangeStyle={{ bottom: 0 }}
          smallLabel
          transparent
          getPopupContainer={true}
          reset={() => {
            setValue('report_due_date', null)
            const search = store[type]

            delete search.report_due_date_before
            delete search.report_due_date_after

            store.setSingleValue(type, search)
          }}
          handleShowDateRange={(value) => setIsDateRangeVisible(value)}
          noLimit
        />
      </Inputs>
      <Inputs>
        <Input
          type={'multiSelect'}
          listHeight={listHeight}
          selectAll={false}
          name={'current_status'}
          control={control}
          options={
            store.user[0]?.report_source_role === 'report_owner'
              ? store.report_source_statuses?.filter((item) => item[0] !== 'monitor' && item[0] !== 'closed' && item[0] !== 'unassigned')
              : store.statuses?.filter((item) => item[0] !== 'monitor' && item[0] !== 'closed')}
          placeholder={'Select Current Status'}
          label={'Current Status'}
          disabled={false}
          handleChange={handleSubmit(onSubmit)}
          selectVars={{ value: 0, name: 1 }}
          smallLabel
          transparent
          getPopupContainer={true}
        />
        <Input
          type={'multiSelect'}
          listHeight={listHeight}
          selectAll={false}
          name={'report_owner'}
          control={control}
          placeholder={'Select Owner'}
          handleChange={handleSubmit(onSubmit)}
          label={'Report Owner'}
          customOptions={membersAdmins}
          showSearch
          smaillList
          smallLabel
          transparent
          getPopupContainer={true}
        />
      </Inputs>
      <Inputs>
        <Input
          type={'multiSelect'}
          listHeight={listHeight}
          selectAll={false}
          name={'ties_to_license'}
          control={control}
          options={store?.legislative_options?.report_source_ties_to_license}
          placeholder={'Select License(s)'}
          handleChange={handleSubmit(onSubmit)}
          label={'Report Ties To License'}
          smallLabel
          transparent
          showSearch
          getPopupContainer={true}
        />
        <Input
          type={'multiSelect'}
          listHeight={100}
          selectAll={false}
          name={'task_assignee'}
          control={control}
          customOptions={store.members
            .filter(
              (item) => item.is_active && item.have_access_to_report_source
            )
            .map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.first_name + ' ' + item.last_name}
              </Select.Option>
            ))}
          placeholder={'Select'}
          handleChange={handleSubmit(onSubmit)}
          label={'Has a Task Assigned To'}
          smallLabel
          smallList
          transparent
          showSearch
          getPopupContainer={true}
        />
      </Inputs>
      <Inputs>
        <CheckboxCover style={{ marginLeft: 0, marginBottom: 0, marginTop: 25 }}>
          <Checkbox
            {...register("past_due_items")}
            checked={watch('past_due_items')}
            onChange={(e) => {
              setValue('past_due_items', e.target.checked);
              handleSubmit(onSubmit)();
            }}
            name={"past_due_items"}
            style={{ marginRight: 5 }}
          />
          <CheckboxName
            style={{
              fontFamily: 'var(--medium)',
              opacity: 1,
              fontSize: 14
            }}>{'Show only past due report items'}
          </CheckboxName>
        </CheckboxCover>
        <CheckboxCover style={{ marginLeft: 0, marginBottom: 0, marginTop: 25 }}>
          <Checkbox
            {...register("only_new_reports")}
            checked={watch('only_new_reports')}
            onChange={(e) => {
              setValue('only_new_reports', e.target.checked);
              handleSubmit(onSubmit)();
            }}
            name={"only_new_reports"}
            style={{ marginRight: 5 }}
          />
          <CheckboxName
            style={{
              fontFamily: 'var(--medium)',
              opacity: 1,
              fontSize: 14
            }}>{'Show only new report items'}
          </CheckboxName>
        </CheckboxCover>
      </Inputs>
      <Inputs>
        <CheckboxCover style={{ marginLeft: 0, marginBottom: 0, marginTop: 25 }}>
          <Checkbox
            {...register("only_new_requirements")}
            checked={watch('only_new_requirements')}
            onChange={(e) => {
              setValue('only_new_requirements', e.target.checked);
              handleSubmit(onSubmit)();
            }}
            name={"only_new_requirements"}
            style={{ marginRight: 5 }}
          />
          <CheckboxName
            style={{
              fontFamily: 'var(--medium)',
              opacity: 1,
              fontSize: 14
            }}>Show only report items with new requirements
          </CheckboxName>
        </CheckboxCover>
        <CheckboxCover style={{ marginLeft: 0, marginBottom: 0, marginTop: 20 }}>
          <Checkbox
            disabled={false}
            checked={remamberLastSearch}
            onChange={(e) => { toggleLastSearch() }}
            name={"remember_last_search"}
            style={{ marginRight: 5 }}
          />
          <CheckboxName
            style={{
              fontFamily: 'var(--medium)',
              opacity: 1,
              fontSize: 14
            }}>Remember search settings
          </CheckboxName>
        </CheckboxCover>
      </Inputs>
    </>
  )

}

export default APCDInpits