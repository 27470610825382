import styled from 'styled-components'

export const Wrap = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: 80% 10%;
  grid-gap: 15px;
  padding-top: 15px;
  margin-bottm: 50px;
  font-family: var(--regular);

  strong {
    font-family: var(--bold);
  }
`

export const WrapEditor = styled('div')`
  border-radius: 6px;
  border: 1px solid #C4CACC;
  width: 100%;
  margin-right: 15px;
  overflow: hidden;

  .public-DraftEditorPlaceholder-inner {
    margin-left: 5px;
    margin-top: 4px;
  }

  p {
    margin-bottom: 0;
  }
`

export const ToolButton = styled('div')`
  width: 44px;
  border-right: 1px solid #C4CACC;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${p => p.active ? '#e3e3e3' : '#fff'};

  &:hover {
    background-color: #eaeaea;
  }
`

export const Toolbar = styled('div')`
  display: flex;
  border-bottom: 1px solid #c4cacc;
  height: 27px;
`
