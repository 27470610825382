

export const notifStatuses = {
  new_compliance_item: 'New Compliance Item',
  assigned_to_a_compliance_item: 'Compliance Item Assigned to You',
  compliance_item_status_changed: 'Compliance Item Status Updates',
  file_uploaded: 'Compliance Document Uploaded',
  file_removed: 'Compliance Document Removed',
  compliance_item_is_due_today: 'Compliance Item Is Due Today',
  compliance_item_is_past_due: 'Compliance Item Is Past Due Today',
  compliance_item_due_soon: 'Compliance Item Due Soon',
  task_completion: 'Completed Task',
  comment_mentions: 'New Comment on Compliance Item',
  updates_to_existing_compliance_items: 'Compliance Item Updated',
  assigned_new_task: 'Compliance Task Assigned to You',
  task_is_due_today: 'Compliance Task Is Due Today',
  task_is_past_due: 'Compliance Task Is Past Due Today',
  task_due_soon: 'Compliance Task Due Soon',
  new_report_item_in_tracking: 'New Report Item',
  assigned_to_a_report_item: 'Report Item Assigned to You',
  report_item_status_changed: 'Report Item Status Updated',
  report_file_uploaded: 'Report Document Uploaded',
  report_file_removed: 'Report Document Removed',
  report_task_completion: 'Completed Report Item Task',
  report_comments: 'New Comment on Report Item',
  new_report_task_assigned: 'Report Task Assigned to You',
  report_item_is_due_today: 'Report Item Is Due Today',
  report_item_are_past_due: 'Report Item Is Past Today',
  report_item_due_soon: 'Report Item Due Soon',
  report_task_are_past_due: 'Report Task Is Past Due',
  report_task_is_due_today: 'Report Task Is Due Today',
  report_task_due_soon: 'Report Item Due Soon',
  new_resource_guide: 'ReSource Guide Added to Your Tracking List',
  legislation_updates: 'Proposed Legislation Updates',
  new_pending_bill: 'Proposed Legislation Updates',
  mentions: 'Compliance Item',
  report_mentions: 'Report Item',
  report_comments_reply: 'New Report Comment Reply',
  comment_reply_site_notifications: 'New Compliance Comment Reply',
  new_license_item_in_tracking: 'New License Item',
  new_renewal_item: 'New Renewal License Item',
  when_assigned_to_a_license_item: 'License Item Assigned to You',
  license_item_status_changed: 'License Item Status Updated',
  updates_to_existing_license: 'License Item Updated',
  license_file_uploaded: 'License Document Uploaded',
  license_file_removed: 'License Document Removed',
  license_task_completion: 'Completed License Item Task',
  license_comments: 'New Comment on License Item',
  license_mentions: 'License Item',
  license_comments_reply: 'New License Comment Reply',
  new_license_task_assigned: 'License Task Assigned to You',
  license_item_is_due_today: 'License Item Is Due Today',
  license_item_are_past_due: 'License Item Is Past Due Today',
  license_item_due_soon: 'License Item Due Soon',
  license_task_is_due_today: 'License Task Is Due Today',
  license_task_are_past_due: 'License Task Is Past Due',
  license_task_due_soon: 'License Task Due Soon',
}