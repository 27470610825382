import React, { useState } from 'react'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import ModalWrap from '../../../../components/common/ModalNew/index'
import { Cancel } from '../Tasks/Modal/style'
import Input from '../../../../components/common/Input/index'
import { useForm } from 'react-hook-form'
import { api } from '../../../../api'
import { useStore } from '../../../../mobx-store/context'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import deleteFileIcon from '../../../../assets/images/delete-orange.svg'
import { useGA4React } from 'ga-4-react'
import { message } from 'antd'

const CertificateModal = ({
  onClose,
  id,
  getCurrentLegislative,
}) => {
  const store = useStore()
  const { control, errors, setValue, handleSubmit } = useForm()
  const [temporaryFiles, setTemporaryFiles] = useState([])
  const ga4React = useGA4React()

  const postFile = (files) => {

    ga4React?.gtag('event', 'file_upload')

    let formData = new FormData()

    formData.append('file', files[0])

    api(`/license_company/${id}/certificates/ `, formData, 'POST', false, true).then((data) => {
      if (!data.errors) {
        getCurrentLegislative()
      } else {
        message.error('File upload failed')
      }
    })
  }

  const addHeldDocs = (temporaryFiles) => {
    temporaryFiles.length > 0 &&
      temporaryFiles.map((item) => {
        return postFile(item)
      })
  }
  const onSubmit = () => {
    addHeldDocs(temporaryFiles)
    // store.loadLegislatives({ page: currentPage }, false, true)
    onClose()
  }

  const addFile = (e) => {
    setTemporaryFiles([...temporaryFiles, [...e.target.files]])
  }


  const deleteFile = (name) => {
    const filtered = temporaryFiles.filter((item) => item[0].name !== name)
    setTemporaryFiles(filtered)
  }


  return (
    <ModalWrap onClose={onClose}>
      <Label>Add License Certificates</Label>
      <Input
        type={'file'}
        name={'file'}
        label={'Attach License Certificate(s):'}
        control={control}
        styleRow={{ marginTop: 10, marginBottom: 10 }}
        handleChange={(e) => addFile(e)}
        multiple
        smallLabel
      />
      {temporaryFiles.map((item, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonOrange text={item[0].name} key={index} />
          <img
            src={deleteFileIcon}
            alt={'delete-icon'}
            onClick={() => deleteFile(item[0].name)}
            style={{ marginLeft: 5, cursor: 'pointer' }}
          />
        </div>
      ))}

      <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
        {<Cancel onClick={onClose}>Cancel</Cancel>}
        <Button
          text={'Add Certificate'}
          style={{ marginLeft: 'auto' }}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </ModalWrap>
  )
}

export default CertificateModal
