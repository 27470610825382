import React, { useState } from 'react';
import { ItemWrap, ItemText, FileName, AddedBy } from './style';
import ButtonOrange from '../../../../../components/common/ButtonOrange';
import moment from 'moment';
import deleteFileIcon from '../../../../../assets/images/delete-orange.svg';
import { downloadDoc } from '../../../../../api';

const ItemBlock = ({ item, deliteFile, materialType, liceneCompanyId }) => {
  const { description, files, author, created, id } = item;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // const downloadDoc = (file) => {
  //   const link = document.createElement('a')
  //   link.href = file.file
  //   link.download = file.file_name
  //   document.body.appendChild(link)
  //   link.click()
  //   link.remove()
  // }

  return (
    <ItemWrap>
      <ItemText dangerouslySetInnerHTML={
        { __html: description }
      } />
      {files.length > 0 && (
        <ButtonOrange
          style={{
            fontFamily: 'var(--bold)',
            fontSize: 14,
            textDecoration: 'underline',
            width: 'fit-content',
            color: '#DF7411',
            // marginTop: 15
          }}
          onClick={toggleExpand}
          text={isExpanded ? 'Hide Documents' : `View Documents (${files.length})`}
        />
      )}
      {isExpanded && (
        <div>
          {files.map((file, index) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FileName key={index}
                target='_blank'
                onClick={() => {
                  const url = `license_company/${liceneCompanyId}/${materialType}/${id}/files/${file.id}/`
                  downloadDoc(url, file.file_name, false, true, false, liceneCompanyId,null, true)
                }}
                download
              >
                {file.file_name}
              </FileName>
              <img
                src={deleteFileIcon}
                alt={'delete-icon'}
                onClick={() => deliteFile(id, file.id)}
                style={{ marginLeft: 5, cursor: 'pointer' }}
              />
            </div>
          ))}
        </div>
      )}
      <AddedBy>
        Added by: {author} {moment(created).format('MM/DD/YYYY')}
      </AddedBy>
    </ItemWrap>
  );
};

export default ItemBlock;