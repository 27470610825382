import { Bottom, Inputs, CalendarInputs, ShowMore, TopLine, Wrap, CheckboxCover, CheckboxName } from './search.style'
import Input from '../../../components/common/Input'
import { Checkbox, Select } from 'antd'

const LicenceSourceInpits = ({ control,
  handleSubmit,
  onSubmit,
  listHeight,
  membersAdmins,
  store,
  activeTab,
  type,
  setValue,
  setIsDateRangeVisible,
  toggleLastSearch,
  remamberLastSearch,
  register,
  watch,
  defaultLicenseExpirationDateRange,
  defaultLicenseInternalDateRange }) => {

  return (
    <>
      <Inputs style={{ marginBottom: '1em' }}>
        <Input
          type={'text'}
          name={'license_title'}
          control={control}
          placeholder={'Type a License Name'}
          label={'License Title'}
          handleChange={handleSubmit(onSubmit)}
          smallLabel
          transparent
          validation={{
            validate: () => true,
          }}
        />
        <Input
          type={'multiSelect'}
          listHeight={listHeight}
          selectAll={false}
          name={'jurisdiction'}
          control={control}
          placeholder={'Select Jurisdiction'}
          options={store?.legislative_options?.license_source_pending_jurisdictions.filter(
            (item) => (item.is_system ? item.enabled : true)
          )}
          label={'Jurisdiction'}
          handleChange={handleSubmit(onSubmit)}
          showDeprecatedLabel
          showDeprecated
          showSearch
          smallLabel
          smaillList
          transparent
          getPopupContainer={true}
        />
      </Inputs>
      <Inputs style={{ marginBottom: '1em' }}>
        <Input
          type={'multiSelect'}
          name={'license_status'}
          control={control}
          placeholder={'Type a License Status'}
          label={'License Status'}
          handleChange={handleSubmit(onSubmit)}
          disabled={activeTab !== 'all'}
          smallLabel
          transparent
          validation={{
            validate: () => true,
          }}
          listHeight={listHeight}
          selectAll={false}
          options={store?.license_source_statuses?.filter(item => item[0] !== 'held')}
          // showDeprecatedLabel
          smaillList
          getPopupContainer={true}
          selectVars={{ value: 0, name: 1 }}
        />
        <Input
          type={'multiSelect'}
          name={'license_type'}
          control={control}
          placeholder={'Type a License Type'}
          label={'License Type'}
          handleChange={handleSubmit(onSubmit)}
          smallLabel
          transparent
          validation={{
            validate: () => true,
          }}
          listHeight={listHeight}
          selectAll={false}
          options={store?.legislative_options?.license_pending_types}
          // showDeprecatedLabel
          smaillList
          getPopupContainer={true}
          selectVars={{ value: 0, name: 1 }}
        />

      </Inputs>
      <Inputs style={{ marginBottom: '1em' }}>
        <Input
          type={'multiSelect'}
          listHeight={listHeight}
          selectAll={false}
          name={'assignee'}
          control={control}
          height={100}
          placeholder={'Select License Owner'}
          customOptions={membersAdmins}
          label={'License Owner'}
          handleChange={handleSubmit(onSubmit)}
          showDeprecatedLabel
          showDeprecated
          showSearch
          smallLabel
          smaillList
          transparent
          getPopupContainer={true}
        />
        <Input
          type={'multiSelect'}
          listHeight={160}
          selectAll={false}
          name={'line_of_business'}
          control={control}
          placeholder={'Select Line of Business'}
          options={store?.legislative_options?.license_source_pending_line_of_business}
          label={'Line of Business'}
          handleChange={handleSubmit(onSubmit)}
          showDeprecatedLabel
          showDeprecated
          showSearch
          smallLabel
          smaillList
          transparent
          getPopupContainer={true}
        />

      </Inputs>
      <Inputs>
        {activeTab === 'held' ?
          <Input
            type={'date_range'}
            name={'expiration_date'}
            defaultValue={defaultLicenseExpirationDateRange}
            disabled={false}
            control={control}
            placeholder={'Select Date Range'}
            label={'Expiration Date'}
            handleChange={handleSubmit(onSubmit)}
            dateRangeStyle={{ bottom: 0 }}
            smallLabel
            transparent
            getPopupContainer={true}
            reset={() => {
              setValue('expiration_date', null)
              const search = store[type]

              delete search.expiration_date_before
              delete search.expiration_date_after

              store.setSingleValue(type, search)
            }}
            handleShowDateRange={(value) => setIsDateRangeVisible(value)}
            noLimit
          />
          : (
            <Input
              type={'date_range'}
              name={'internal_due_date'}
              defaultValue={defaultLicenseInternalDateRange}
              disabled={false}
              control={control}
              placeholder={'Select Date Range'}
              label={'Internal Due Date'}
              handleChange={handleSubmit(onSubmit)}
              dateRangeStyle={{ bottom: 0 }}
              smallLabel
              transparent
              getPopupContainer={true}
              reset={() => {
                setValue('internal_due_date', null)
                const search = store[type]

                delete search.internal_due_date_before
                delete search.internal_due_date_after

                store.setSingleValue(type, search)
              }}
              handleShowDateRange={(value) => setIsDateRangeVisible(value)}
              noLimit
            />

          )}
        <Input
          type={'multiSelect'}
          listHeight={100}
          selectAll={false}
          name={'assignment_owner'}
          control={control}
          placeholder={'Select Assignment Owner'}
          customOptions={store.members
            .filter(
              (item) => item.is_active && item.have_access_to_license_source
            )
            .map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.first_name + ' ' + item.last_name}
              </Select.Option>
            ))}
          label={'Has A Task Assigned To'}
          handleChange={handleSubmit(onSubmit)}
          showDeprecatedLabel
          showDeprecated
          showSearch
          smallLabel
          smaillList
          transparent
          getPopupContainer={true}
        />
      </Inputs>
      <Inputs>
        <CheckboxCover style={{ marginLeft: 0, marginBottom: 0, marginTop: 20 }}>
          <Checkbox
            disabled={false}
            checked={remamberLastSearch}
            onChange={(e) => { toggleLastSearch() }}
            name={"remember_last_search"}
            style={{ marginRight: 5 }}
          />
          <CheckboxName
            style={{
              fontFamily: 'var(--medium)',
              opacity: 1,
              fontSize: 14
            }}>Remember search settings
          </CheckboxName>
        </CheckboxCover>
      </Inputs>
      <CheckboxCover style={{ marginLeft: 0, marginBottom: 0, marginTop: 20 }}>
        <Checkbox
        {...register("only_new_licenses")}
          checked={watch("only_new_licenses")}
          onChange={(e) => {
            setValue('only_new_licenses', e.target.checked);
            handleSubmit(onSubmit)();
          }}
          name={"only_new_licenses"}
          style={{ marginRight: 5 }}
        />
        <CheckboxName
          style={{
            fontFamily: 'var(--medium)',
            opacity: 1,
            fontSize: 14
          }}>Show only new licenses added
        </CheckboxName>
      </CheckboxCover>
    </>
  )

}

export default LicenceSourceInpits